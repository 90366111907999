html {
  -webkit-text-size-adjust: 100%;
  scroll-behavior: smooth;
}



/* NProgress Plugin */
#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  background: #1471c1;

  position: fixed;
  z-index: 1031;
  top: 0;
  left: 0;

  width: 100%;
  height: 2px;
}
#nprogress .peg {
  display: none;
}
#nprogress .spinner {
  display: none;
}



/* Filepond Plugin */
.filepond--action-process-item{
  visibility: hidden;
}